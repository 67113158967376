<template>
    <v-container>
        <base-section-heading
        title="about.workflow.title">
        </base-section-heading>
        
        <BaseImage
        :imageName="imageName"
        :altText="altText"
        :captionText="captionText"
        ></BaseImage>

        <SectionWithAlert
        v-for="section in sections"
        :key="section.key"
        :id="section.id"
        :title="section.title"
        :text="section.text"
        :introSeeAlso="section.introSeeAlso"
        :seeAlso="section.seeAlso"
        :link="section.link"
        >
        </SectionWithAlert>
        
    </v-container>
</template>

<script>
import SectionWithAlert from '@/components/docs/SectionWithAlert'
import BaseImage from '@/components/base/BaseImage'
export default {
    components: {
      SectionWithAlert,
      BaseImage
    },
    data() {
        return {
            imageName: 'about.workflow.imageName',
            altText: 'about.workflow.altText',
            captionText: 'about.workflow.captionText',
            sections: [
                { 
                    key: 1,
                    id: 'survey-transcription-section',    
                    title: 'about.workflow.section-1.title',
                    text: 'about.workflow.section-1.text',
                    introSeeAlso: 'about.workflow.section-1.introSeeAlso',
                    seeAlso: 'about.workflow.section-1.seeAlso',
                    link: 'about.workflow.section-1.link'
                },
                { 
                    key: 2, 
                    id: 'data-model-section',
                    title: 'about.workflow.section-2.title',
                    text: 'about.workflow.section-2.text',
                    introSeeAlso: 'about.workflow.section-2.introSeeAlso',
                    seeAlso: 'about.workflow.section-2.seeAlso',
                    link: 'about.workflow.section-2.link'
                },
                { 
                    key: 3, 
                    id: 'markup-metadata-processing-section',
                    title: 'about.workflow.section-3.title',
                    text: 'about.workflow.section-3.text',
                    introSeeAlso: 'about.workflow.section-3.introSeeAlso',
                    seeAlso: 'about.workflow.section-3.seeAlso',
                    link: 'about.workflow.section-3.link'
                },
                { 
                    key: 4, 
                    id: 'site-design-section',
                    title: 'about.workflow.section-4.title',
                    text: 'about.workflow.section-4.text',
                    introSeeAlso: 'about.workflow.section-4.introSeeAlso',
                    seeAlso: 'about.workflow.section-4.seeAlso',
                    link: 'about.workflow.section-4.link'
                },
                { 
                    key: 5, 
                    id: 'documentation-section',
                    title: 'about.workflow.section-5.title',
                    text: 'about.workflow.section-5.text',
                    introSeeAlso: 'about.workflow.section-5.introSeeAlso',
                    seeAlso: 'about.workflow.section-5.seeAlso',
                    link: 'about.workflow.section-5.link'
                }
            ],
        }
    }
}
</script>

<style scoped>
a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
div >>> a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
</style>
